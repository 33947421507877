require('intersection-observer')

let lazyImages = document.querySelectorAll('.lazy')
let options = {
    root: null,
    rootMargin: '0px',
    threshold: .1
  }

let callback = (entries, observer) => { 
    entries.forEach(entry => {
      if (entry.isIntersecting) {
        lazyImages.forEach((image) => {
            image.setAttribute('src', image.getAttribute('data-src'))
        })
      }
    });
};


let observer = new IntersectionObserver(callback, options);


let target1 = document.querySelector('#lazy-product-pics');
let target = document.querySelector('#lazy-email-container');

if (target1) {
  observer.observe(target1);
}

if (target) {
  observer.observe(target);
}



