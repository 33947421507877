import ScrollMagic from 'scrollmagic'

const windowSize = window.screen.width
let mockTrigger;
if (window.screen.width > 752) {
    mockTrigger = '#mockups-container'
} else {
    mockTrigger = '#mockups-container'
}


// let mockupTrigger = document.getElementById('mockup-trigger')
let topCurvesTrigger = document.getElementById('top-flow-curves')
let bottomCurvesTrigger = document.getElementById('bottom-curves')

if (mockTrigger && topCurvesTrigger && bottomCurvesTrigger) {
    
    var controller = new ScrollMagic.Controller();

    //scene for product mockups images
    new ScrollMagic.Scene({
        triggerElement: mockTrigger,
        triggerHook: .99,
        duration: "140%",
        offset: 0
    })
    .setClassToggle("#mockups-container", "visible")
    // .addIndicators()
    .addTo(controller);
    
    
    //scene for  top curves
    new ScrollMagic.Scene({
        triggerElement: "#top-flow-curves",
        triggerHook: .95,
        duration: "105%",
        offset: 50
    })
    .setClassToggle(".toparrow", "visible")
    .addTo(controller);
    
    
    //scene for bottom curves
    new ScrollMagic.Scene({
        triggerElement: "#bottom-curves",
        triggerHook: .95,
        duration: "105%",
        offset: 50
    })
    .setClassToggle(".bottomarrow", "visible")
    .addTo(controller);

}

