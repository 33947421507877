import './scrollMagic'
import './lazy-load'
import './styles/style.scss'

//check for service worker
if ('serviceWorker' in navigator) {
  navigator.serviceWorker.register('./serviceworker.js')
  .then((reg) => {
    // registration worked
    console.log('Registration succeeded. Scope is ' + reg.scope);
  }).catch((error) => {
    // registration failed
    console.log('Registration failed with ' + error);
  });
}

const emailErr = document.getElementById('email-error')
// const emailSuccess = document.getElementById('email-success')
const submitButton = document.getElementById('mc-embedded-subscribe')
if (submitButton) {
  submitButton.onclick = (e) => {
    if (grecaptcha.getResponse() === "") {
      e.preventDefault()
      emailErr.style.display = 'block'
    } else {
      emailErr.style.display = 'none'
    }
  }
}

if (submitButton) {
  //google recapctcha callback to load recaptcha form
  var reCaptcha = function() {
    grecaptcha.render('recap', {
      'sitekey' : '6Ld-yL4UAAAAAPAXr9lZYVBUE7H26pDkzM2s_VTT'
    });
  };
  window.reCaptcha = reCaptcha
}

